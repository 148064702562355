import { Component, NgZone } from '@angular/core';
import { environment } from '../environments/environment';
import { Capacitor } from '@capacitor/core';
import { Router, NavigationStart, NavigationEnd, GuardsCheckStart } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { LangService } from './services/lang.service';
import { GeneralService } from './services/general.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { Market } from '@ionic-native/market/ngx';
import { CartService } from './services/cart.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';
import { Geolocation } from '@capacitor/geolocation';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { titleEn: 'Home', titleAr: 'الصفحة الرئيسية', icon: 'home', svg: true, url: '' },
    { titleEn: 'My account', titleAr: 'حسابي', icon: 'qr-code-outline', svg: false, url: 'account' },
    { titleEn: 'My orders', titleAr: 'طلباتي', icon: 'orders', svg: true, url: 'orders' },
    { titleEn: 'My subscribtions', titleAr: 'إشتراكاتي', icon: 'subscribe', svg: true, url: 'subscriptions' },
    { titleEn: 'Discounts', titleAr: 'الخصومات', icon: 'discounts', svg: true, url: 'discounts' },
    { titleEn: 'Favorites', titleAr: 'المفضلة', icon: 'heart-outline', svg: false, url: 'favorites' },
    { titleEn: 'Contact us', titleAr: 'تواصل معنا', icon: 'contact-us', svg: true, url: 'contact-us' },
    { titleEn: 'About us', titleAr: 'من نحن', icon: 'about-us', svg: true, url: 'about-us' },
    { titleEn: 'Branches', titleAr: 'الفروع', icon: 'map', svg: true, url: 'map' },
    { titleEn: 'News', titleAr: 'الأخبار', icon: 'news', svg: true, url: 'news' },
  ];
  websiteLink = environment.website;
  permitedNotif: any;
  minOrder: any;
  percDownload = 0;
  percUpdate = 0;
  nbrOfItemSubscription = 0;
  platform = Capacitor.getPlatform();
  isNative = Capacitor.isNativePlatform();
  permission = false;
  isNotif = false;
  splash = true;
  update = false;
  loading: boolean = true;
  init: boolean = true;
  constructor(
    private translate: TranslateService,
    public l: LangService,
    private alert: AlertController,
    private router: Router,
    private plt: Platform,
    private services: GeneralService,
    private market: Market,
    private cart: CartService,
    private zone: NgZone,
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof GuardsCheckStart))
      .subscribe((event) =>{
        if (event instanceof NavigationStart) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 7000);
        } else if (event instanceof NavigationEnd) {
          setTimeout(() => {
            this.loading = false;
            this.init = false;
          }, 2000);
        } else if (event instanceof GuardsCheckStart) {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        }
    });
    this.cart.nbrOnChangeSubscription().subscribe((nbr: number) => {
      this.nbrOfItemSubscription = nbr;
    });
    this.initialize();
  }

  async initialize() {
    const settings = await this.getSettings();
    this.minOrder = settings.minOrder;
    this.versionShouldBeChanged(settings);
    this.setLang();
    this.listenUrlOpen();
    this.useOneSignal();

    this.hideSplashScreen();
    this.getLocation();
  }

  async getLocation() {
    if (Capacitor.isNativePlatform()) {
      const { coarseLocation, location } = await Geolocation.checkPermissions();
      if (coarseLocation === 'granted' || location === 'granted') {
        this.nativeLocation();
      } else {
        const req = await Geolocation.requestPermissions();
        if (req.coarseLocation === 'granted' || req.location === 'granted') {
          this.nativeLocation();
        }
      }
    }
  }

  async nativeLocation() {
    try {
      const id = await Geolocation.watchPosition({
        enableHighAccuracy: true
      }, async (coordinates: any, err) => {
        if (coordinates && coordinates.coords.altitudeAccuracy < 33) {
          Geolocation.clearWatch({ id });
          localStorage.setItem('location', JSON.stringify({
            coords: coordinates.coords,
            expire: Date.now() + (60000 * 30)
          }));
        }
      });
    } catch (error) {
      console.log('error', error)
    }
  }

  hideSplashScreen() {
    setTimeout(async () => {
      this.splash = false;
    }, 6000);
  }

  async useOneSignal() {
    if (this.platform !== 'web') {
      await this.plt.ready();
      this.isNotif = true;
      const APP_ID = '8e28f333-1dc3-4ad4-9bfa-301fc894a719';
      const senderId = '7267376567';
      OneSignal.setAppId(APP_ID);
      OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {

      });
      OneSignal.promptForPushNotificationsWithUserResponse(true, (_accepted) => {
      })
      OneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent: any) => {
        notificationReceivedEvent.complete(notificationReceivedEvent.notification);
      })
      const notif = localStorage.getItem('notification');
      if (notif !== null) {
        this.permission = JSON.parse(notif);
        OneSignal.disablePush(this.permission)
      } else {
        this.permission = true;
        OneSignal.disablePush(true)
      }
    }
  }

  listenUrlOpen() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('.app').pop();
        if (slug) {
          const restOfUrl = slug.split('#');
          this.router.navigateByUrl(restOfUrl[1] === '' ? '/' : restOfUrl[1]);
        }
      });
    });
  }

  setLang() {
    if (localStorage.getItem('lang')) {
      this.translate.use(this.l.get);
    } else {
      this.translate.setDefaultLang('ar');
    }
  }
  async versionShouldBeChanged(settings:any) {
    const { versionRequired, updateRequired } = settings[Capacitor.getPlatform()] || settings;
    const { version } = environment;
    const isNative = Capacitor.isNativePlatform();
    // console.log("settings[Capacitor.getPlatform()]", settings[Capacitor.getPlatform()]);
    // console.log("Version", version);

    if (isNative && versionRequired !== version && updateRequired) {
        const [header, message]: any = await Promise.all([
          firstValueFrom(this.translate.get('ATTENTION')),
          firstValueFrom(this.translate.get('UPDATE REQUIRED'))
        ]);
        const alert = await this.alert.create({
          header,
          message,
          backdropDismiss: false,
          cssClass: 'center-alert',
          buttons: [{
            text: 'Ok',
            handler: async () => {
              if (this.plt.is('ios')) {
                this.market.open('id1434999965');
              } else if (this.plt.is('android')) {
                this.market.open('com.almamlakasweets.almamlakasweets');
              }
              this.versionShouldBeChanged(settings);
            },
            cssClass: 'alert-button'
          }]
        });
        await alert.present();
    } else if (!isNative && versionRequired !== version && updateRequired) {
      location.reload();
    }
  }

  getSettings = () => this.services.get('settings', false, 0, false)

  changeNotification(e: any) {
    OneSignal.disablePush(e.detail.checked)
    this.permission = e.detail.checked;
    localStorage.setItem('notification', JSON.stringify(e.detail.checked));
  }

  changeLanguage(e: any) {
    this.translate.use(e.detail.value);
    this.l.set = e.detail.value;
  }

}
