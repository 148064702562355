import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Market } from '@ionic-native/market/ngx';
import { LoginStep1Component } from './components/login-step1/login-step1.component';
import { LoginStep2Component } from './components/login-step2/login-step2.component';
import { LoginComponent } from './components/login/login.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { FormsModule } from '@angular/forms';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';

export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, '../assets/i18n/', '.json');

@NgModule({
  declarations: [AppComponent, LoginComponent, LoginStep1Component, LoginStep2Component],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    AppRoutingModule,
    NgOtpInputModule,
  ],
  providers: [
    Market, LocationAccuracy,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
