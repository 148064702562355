<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" menuId="arabic" type="overlay" side="end" swipeGesture="false">
      <ion-content class="ion-padding-top">
        <div class="cat-title"></div>
        <ion-list id="inbox-list">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]" lines="none" detail="false"
              [dir]="'dir' | translate" routerLinkActive="selected" class="menu-item-toogle" class="menus"
              [ngClass]="{'sub': p.url === 'subscriptions', 'home': p.url === '' }">
              <ion-icon slot="start" [name]="p.icon" *ngIf="!p.svg" class="menu-icon" color="primary"></ion-icon>
              <ion-icon slot="start" [src]="'assets/icon/' + p.icon + '.svg'" *ngIf="p.svg"
                class="menu-icon" color="primary"></ion-icon>
              <ion-label *ngIf="l.get === 'ar'; else linkInEn" color="primary">{{ p.titleAr }} <span
                  *ngIf="p.url === 'subscriptions'">({{'NEW' | translate}})</span></ion-label>
              <ng-template #linkInEn>
                <ion-label color="primary">{{ p.titleEn }} <span *ngIf="p.url === 'subscriptions'">({{'NEW' |
                    translate}})</span></ion-label>
              </ng-template>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-list id="inbox-list" lines="none">
          <ion-item slot="start" lines="none" class="ion-text-center">
            <ion-label class="ion-text-wrap ion-text-center delivery" [dir]="'dir' | translate" color="secondary">
              <ion-icon src="assets/icon/delivery.svg" color="secondary"></ion-icon>&nbsp;
              <span [translate]="'Free delivery from'" [translateParams]="{ value: minOrder}"></span>
            </ion-label>
          </ion-item>
          <ion-menu-toggle auto-hide="false">
            <ion-button [routerLink]="['terms-of-use']" expand="block" fill="clear" class="menu-button" size="small"
              style="font-size: 0.7rem">
              {{'TERMS OF CONDITIONS' | translate}}
            </ion-button>
          </ion-menu-toggle>
          <ion-item [dir]="'dir' | translate" *ngIf="isNotif" class="menu-item-toogle notif">
            <ion-toggle (ionChange)="changeNotification($event)" [checked]="permission" [enableOnOffLabels]="true"
              color="secondary">{{'Norifications' | translate}}</ion-toggle>
          </ion-item>
              <!-- <div style="position: absolute; top: 0; left: 0; z-index: 9999;"> -->

    <!-- </div> -->
          <ion-segment (ionChange)="changeLanguage($event)" mode="md" [value]="l.get" class="menu-item-toogle">
            <ion-segment-button value="ar">
              <ion-label>العربي</ion-label>
            </ion-segment-button>
            <ion-segment-button value="en">
              <ion-label>English</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-list>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>
</ion-app>
<div class="loader-f" [ngClass]="{'init': init}" *ngIf="loading" >
  <ion-icon src="assets/icon/logo-strock.svg" style="font-size: 260px;"></ion-icon>
  <ion-icon src="assets/icon/custum-spinner.svg" style="font-size: 350px;" class="big-spinner" ></ion-icon>
</div>
