import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoginComponent } from '../components/login/login.component';
import { CartService } from '../services/cart.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateGuard implements CanActivate {
  constructor(
    public translate: TranslateService,
    private modal: ModalController,
    private router: Router,
    private st: StorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.isLogin().then(async (isLogin) => {
      if (!isLogin) {
        return this.presentLogin().then(async () => {
          const isLogin = await this.isLogin()
          const location = window.document.location;
          const originalHashValue = location.hash;
          if (!isLogin && [
            '#/account', '#/orders', '#/subscriptions', '#/favorites', '#/checkout','#/thankyou'
          ].indexOf(originalHashValue) > -1) {
            this.router.navigate([''])
          }
          return isLogin;
        });
      } else {
        return await this.isLogin();
      }
    })
  }

  async presentLogin() {
    const modal = await this.modal.create({
      component: LoginComponent,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async isLogin() {
    const token = await this.st.getItem('token');
    if (token !== null && !token.includes('undefined')) {
      const checkToken = (exp: number) => Date.now() <= exp * 1000;
      const tokenPayload: any = jwtDecode(token);
      return checkToken(tokenPayload.exp) && tokenPayload.role === 'user';
    } else {
      return false;
    }
  }
}
