import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  public lang: any;
  constructor() { }

  set set(lang: string) {
    localStorage.setItem('lang', lang);
  }

  get get() {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar');
    }
    return localStorage.getItem('lang') || 'ar';
  }
}
