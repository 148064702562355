import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';
export enum NBR {
  nbr
}
@Injectable({
  providedIn: 'root',
})
export class CartService {
  data: any;
  private status: BehaviorSubject<number> = new BehaviorSubject(0);
  private statusSubscribed: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor(
    public toast: ToastController,
    public translate: TranslateService,
    private st: StorageService
  ) {
    this.noOfItems();
    this.noOfSubscribedItems();
  }

  async add(product: { productId: any; name?: any; nameAr?: any; imageUrl?: any; sizeOption: any; Quantity: any; itemTotalPrice?: any; }, type: string) {
    return this.st.getItem(type === 'normal' ? 'cartItem' : 'cartSubscription').then((cart) => {
      if (cart == null || !cart.length) {
        product.itemTotalPrice = product.Quantity * (Number(product.sizeOption.specialPrice) || Number(product.sizeOption.value));
        const itemInCart: any[] = [];
        itemInCart.push(product);
        return this.st.setItem(type === 'normal' ? 'cartItem' : 'cartSubscription', itemInCart, true, Date.now() + (3600 * 1000 * 3)).then((ff) => {
          if (type === 'normal') {
            this.noOfItems();
          } else {
            this.noOfSubscribedItems()
          }
        });
      } else {
        let isNew = true;
        cart.forEach((p: { productId: any; sizeOption: { pname: any; value: any; }; Quantity: number; itemTotalPrice: number; }) => {
          if (
            p.productId === product.productId &&
            p.sizeOption.pname === product.sizeOption.pname &&
            p.sizeOption.value === product.sizeOption.value
          ) {
            p.Quantity += product.Quantity;
            p.itemTotalPrice = p.Quantity * (Number(product.sizeOption.specialPrice) || Number(product.sizeOption.value));
            const halalaDiscountWithVAT = p.itemTotalPrice - Math.floor(p.itemTotalPrice);
            if (halalaDiscountWithVAT > 0.99 || halalaDiscountWithVAT < 0.012) {
              p.itemTotalPrice = Math.round(p.itemTotalPrice);
            }
            isNew = false;
          }
        });
        if (isNew) {
          product.itemTotalPrice = product.Quantity * (Number(product.sizeOption.specialPrice) || Number(product.sizeOption.value));
          cart.push(product);
        };
        return this.st.setItem(type === 'normal' ? 'cartItem' : 'cartSubscription', cart, true, Date.now() + (3600 * 1000 * 3)).then(() => {
          if (type === 'normal') {
            this.noOfItems();
          } else {
            this.noOfSubscribedItems()
          }
        });
      }
    });
  }

  public nbrOnChange(): Observable<NBR> {
    return this.status.asObservable();
  }

  public nbrOnChangeSubscription(): Observable<NBR> {
    return this.statusSubscribed.asObservable();
  }

  cartTotalValue(type: string) {
    return this.st.getItem(type === 'normal' ? 'cartItem' : 'cartSubscription').then((cartItems) => {
      let initPrice = 0;
      cartItems.forEach((item: { Quantity: any; sizeOption: { specialPrice: any; value: any; }; }) => {
        const quantity = item.Quantity;
        const sizeOption = !item.sizeOption.specialPrice ? item.sizeOption.value : item.sizeOption.specialPrice;
        const priceByQuantity = (quantity * sizeOption); // price of one item by quantity
        initPrice = initPrice + priceByQuantity;
      });
      return initPrice;
    })
  }

  noOfItems() {
    return this.st.getItem('cartItem').then((cartItems) => {
      const nbr = !cartItems ? 0 :
        cartItems.reduce((acc: any, curr: { Quantity: any; }) => acc + curr.Quantity, 0);
      this.status.next(nbr);
      return nbr;
    });
  }
  noOfSubscribedItems() {
    return this.st.getItem('cartSubscription').then((cartItems) => {
      const nbr = !cartItems ? 0 :
        cartItems.reduce((acc: any, curr: { Quantity: any; }) => acc + curr.Quantity, 0);
      this.statusSubscribed.next(nbr);
      return nbr;
    });
  }

  public calculatePrice(tax: number, coupon:any = null, type = 'normal') {
    return this.st.getItem(type === 'normal' ? 'cartItem' : 'cartSubscription').then((cartItems) => {
      const obj: any = {};
      obj.subTotalPriceBeforeDiscount = cartItems.reduce((acc: number, cart: { sizeOption: { value: any; }; Quantity: number; }) => acc + (Number(cart.sizeOption.value) * cart.Quantity), 0);
      obj.subTotalPrice = cartItems.reduce((acc: any, { itemTotalPrice }: any) => acc + itemTotalPrice, 0);
      obj.deductedPrice = 0;
      obj.couponDiscount = 0;
      obj.couponDiscountPercentage = 0;
      if (coupon) {
        const subTotalPrice = obj.subTotalPrice;
        obj.subTotalPrice = subTotalPrice / 100 * (100 - coupon.offerPercentage);
        obj.deductedAmountByCoupon = obj.subTotalPrice - subTotalPrice;
        obj.couponDiscountPercentage = coupon.offerPercentage;
      }

      obj.taxAmount = Number(((tax / 100) * obj.subTotalPrice));
      obj.grandTotal = (obj.subTotalPrice + obj.taxAmount);
      let halalaDiscountWithVAT = obj.grandTotal - Math.floor(obj.grandTotal);
      if (halalaDiscountWithVAT > 0.99 || halalaDiscountWithVAT < 0.012) {
        obj.grandTotal = Math.round(obj.grandTotal);
        halalaDiscountWithVAT = 0;
      }
      const halalaDiscountWithoutVAT = halalaDiscountWithVAT * 100 / (100 + tax);
      obj.subTotalPrice -= halalaDiscountWithoutVAT;
      obj.taxAmount = Number(((tax / 100) * obj.subTotalPrice));
      obj.grandTotal = Math.round(obj.taxAmount + obj.subTotalPrice);
      obj.deductedPrice = obj.subTotalPrice - obj.subTotalPriceBeforeDiscount;
      return obj;
    })
  }
}
