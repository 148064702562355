import { Component } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoginStep1Component } from "../login-step1/login-step1.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  login1 = LoginStep1Component;
  constructor(
    public action: ActionSheetController,
    public translate: TranslateService) {

    }
}
